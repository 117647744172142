































































import { Vue, Component, Prop } from 'vue-property-decorator'
import {
  BAvatar,
  BBadge, BButton, BCard, BCardTitle, BCol,
  BDropdown,
  BDropdownItem, BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BPagination, BRow, BSpinner
} from "bootstrap-vue";
import {VueGoodTable} from 'vue-good-table'
import {VForm} from "@/global";


@Component({
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BSpinner
  }
})
export default class Platforms extends Vue {
  $refs!: {
    createForm: VForm
  }


  columns = [
    {
      label: 'Name',
      field: 'name',
    },
  ]

  rows: MaintenanceAPI.VincereInstance.Item[] = [];

  async created() {
    await this.getItems();
  }

  async getItems() {
    this.rows = await this.$api.maintenance.vincere.GetList();
  }

  async goTo(params) {
    await this.$router.push({
      name: "maintenance-vincereinstance",
      params: {
        identifier: params.row.id
      }
    })
  }

  // Create Model
  createModel = {
    loading: false,
    domain: null,
  }

  async create(bvModalEvt) {
    this.createModel.loading = true;
    bvModalEvt.preventDefault();

    if(!await this.$refs.createForm.validate()) {
      this.createModel.loading = false;
      return;
    }


    const res = await this.$api.maintenance.vincere.Create({domain: this.createModel.domain});
    if(res != null)
      await this.$router.push({
        name: "maintenance-vincereinstance",
        params: {
          identifier: res.id,
          action: "edit"
        }
      })

    this.createModel.loading = false;
  }

  cancel() {
    this.$refs.createForm.reset();
    this.$set(this, "createModel", {name: null})
  }
}
